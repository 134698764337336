<app-sidebar>
    <p-toast></p-toast>
    <form [formGroup]="contractForm">
        <div class="custom-contract-card">
            <h2 class="mt-0">Neuwagen</h2>
            <div class="mb-4">
                <p-button (click)="toggle(0)" [icon]="activeState[0] ? 'pi pi-minus' : 'pi pi-plus'"
                    label="Vorvertragliche Informationen" styleClass="p-button-text"></p-button>
                <p-button (click)="toggle(1)" [icon]="activeState[0] ? 'pi pi-minus' : 'pi pi-plus'"
                    label="Verbindliche Bestellung" styleClass="p-button-text ml-2"></p-button>
            </div>
            <p-accordion [multiple]="true">
                <p-accordionTab [(selected)]="activeState[0]" header="Vorvertragliche Informationen">
                    <h3>Informationen zum Käufer</h3>
                    <div class="grid mt-5">
                        <div class="col-4 p-fluid">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="contractDataType"
                                    formControlName="contracttype" optionLabel="name"
                                    optionValue="value"></p-dropdown>
                                <label>Privat/Gewerblich</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['contracttype'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('contracttype')?.getError('required')">
                                    Das Feld Privat/Gewerblich ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <!-- <div *ngIf="isDetailView" class="col-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="contracttype" pInputText type="text" />
                                <label>Privat/Gewerblich</label>
                            </span>
                        </div> -->
                        <div class="col-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="vehicle" pInputText type="text" />
                                <label>Fahrzeug</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['vehicle'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('vehicle')?.getError('required')">
                                    Das Feld Fahrzeug ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="grid mt-5">
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyersalutation" pInputText type="text" />
                                <label>Anrede</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['buyersalutation'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('buyersalutation')?.getError('required')">
                                    Das Feld Anrede ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyerfirstname" pInputText type="text" />
                                <label>Vorname(n)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyerlastname" pInputText type="text" />
                                <label>Nachname</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['buyerlastname'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('buyerlastname')?.getError('required')">
                                    Das Feld Nachname ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyerstreet" pInputText type="text" />
                                <label>Strasse</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['buyerstreet'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('buyerstreet')?.getError('required')">
                                    Das Feld Strasse ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyerzip" pInputText type="text" />
                                <label>Postleitzahl</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['buyerzip'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('buyerzip')?.getError('required')">
                                    Das Feld Postleitzahl ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyercity" pInputText type="text" />
                                <label>Ort</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['buyercity'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('buyercity')?.getError('required')">
                                    Das Feld Ort ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyerphone" pInputText type="text" />
                                <label>Telefon</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyermobile" pInputText type="text" />
                                <label>Mobil</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="buyeremail" pInputText type="text" />
                                <label>E-Mail</label>
                            </span>
                        </div>
                    </div>
                    <p class="mt-4"></p>
                    <p-toast></p-toast>
                    <p-table [tableStyle]="{'min-width': '50rem'}" [value]="newContractInformations" dataKey="id"
                        editMode="row">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    <p>Vereinbarungen</p>
                                    <span *ngIf="!contractIsCreated">
                                        <div class="grid align-items-center">
                                            <button (click)="openSelectionPopup()" class="mr-2" icon="pi pi-plus"
                                                label="Vereinbarung auswählen" pButton></button>
                                            <button (click)="showAgreementPopup()" icon="pi pi-plus"
                                                label="Vereinbarung erstellen" pButton></button>
                                        </div>
                                    </span>
                                    <span *ngIf="contractIsCreated">
                                        <h4>Vereinbarungen</h4>
                                    </span>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template let-preContractInfo pTemplate="body">
                            <tr>
                                <td [class]="preContractInfo.placeholder ? 'placeholderrow' : ''"
                                    [innerHTML]="preContractInfo.content"></td>
                                <td [innerHTML]="preContractInfo.description"></td>
                                <td>
                                    <span *ngIf="!contractIsCreated">
                                        <button (click)="editPrecontractInformation(preContractInfo)"
                                            icon="pi pi-pencil" pButton></button>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="!contractIsCreated">
                                        <button (click)="removePrecontractInformation(preContractInfo)"
                                            icon="pi pi-trash" pButton></button>
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-accordionTab>
                <p-accordionTab [(selected)]="activeState[0]" header="Verbindliche Bestellung">
                    <h3>Informationen zur Bestellung</h3>
                    <div class="grid mt-5">
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="brand" pInputText type="text" />
                                <label>Marke</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['brand'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('brand')?.getError('required')">
                                    Das Feld Marke ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="equipment" pInputText type="text" />
                                <label>Modell / Type in Serienmäßiger Ausführung</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['equipment'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('equipment')?.getError('required')">
                                    Das Feld Modell ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="engine" pInputText type="text" />
                                <label>Motor</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['engine'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('engine')?.getError('required')">
                                    Das Feld Motor ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="color" pInputText type="text" />
                                <label>Farbe</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['color'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('color')?.getError('required')">
                                    Das Feld Farbe ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="interior" pInputText type="text" />
                                <label>Polster/Farbe</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['interior'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('interior')?.getError('required')">
                                    Das Feld Polster/Farbe ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="tires" pInputText type="text" />
                                <label>Bereifung</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="vin" pInputText type="text" />
                                <label>Fahrgestellnummer</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['vin'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('vin')?.getError('required')">
                                    Das Feld Fahrgestellnummer ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="mileage" pInputText type="text" />
                                <label>Kilometerstand</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="firstregistration" pInputText type="text" />
                                <label>Erstzulassung</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="mainexaminationemissiontest" pInputText type="text" />
                                <label>HU | AU</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="previousowner" pInputText type="text" />
                                <label>Vorbesitzer</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="deliverydate" pInputText type="text">
                                <label>Unverbindlicher Liefertermin/Lieferzeit</label>
                                <!--TODO: tooltip -->
                            </span>
                            <ng-container *ngIf="contractForm.controls['deliverydate'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('deliverydate')?.getError('required')">
                                    Das Feld Unverbindlicher Liefertermin/Lieferzeit ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <p-inputMask formControlName="contractdate" mask="99.99.9999"
                                    placeholder="dd.mm.yyyy"></p-inputMask>
                                <label>Vertragsdatum</label>
                            </span>
                            <ng-container *ngIf="contractForm.controls['contractdate'].touched">
                                <span class="text-danger" *ngIf="contractForm.get('contractdate')?.getError('required')">
                                    Das Feld Vertragsdatum ist ein Pflichtfeld.
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="grid mt-4">
                        <div class="col-4 p-fluid">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="contractDataPaymentType"
                                            formControlName="paymenttype" optionLabel="name"
                                            optionValue="value"></p-dropdown>
                                <label>Zahlungsweise</label>
                            </span>
                        </div>
                       
                    </div>
                    <div class="grid mt-5">
                        <div class="col-12">
                            <h3>Berechnung</h3>
                        </div>
                        <div class="col-12 mt-4 d-flex" *ngIf="type !== 'view'">
                            <div class="p-field-radiobutton mb-3">
                                <p-radioButton id="MwSt" name="MwSt" value="val1" [(ngModel)]="selectedValue" (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}" inputId="MwSt"></p-radioButton>
                                <label for="MwSt" class="ml-2">MwSt. ausweisbar</label>
                            </div>
                            <div class="p-field-radiobutton ml-3">
                                <p-radioButton id="Nicht" name="Nicht" value="val2" [(ngModel)]="selectedValue" (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}" inputId="Nicht"></p-radioButton>
                                <label for="Nicht" class="ml-2">Nicht ausweisbar</label>
                            </div>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="priceChangedForNoTaxCalculation()" formControlName="vehiclepricegross"
                                    pInputText type="number" />
                                <label>Fahrzeugpreis</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="priceNetChanged()" formControlName="vehiclepricenet"
                                       pInputText type="number" />
                                <label>Fahrzeugpreis (Netto)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="priceGrossChanged()" formControlName="vehiclepricegross"
                                       pInputText type="number" />
                                <label>Fahrzeugpreis (Brutto)</label>
                            </span>
                        </div>

                        <div class="col-9 p-fluid">
                            <div class="custom-border"></div>
                        </div>

                        <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="discountChangedForNoTaxCalculation()" formControlName="discountgross" pInputText
                                    type="number" />
                                <label>- Nachlass</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="discountNetChanged()" formControlName="discountnet" pInputText
                                       type="number" />
                                <label>- Nachlass (Netto)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="discountGrossChanged()" formControlName="discountgross" pInputText
                                       type="number" />
                                <label>- Nachlass (Brutto)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                            <!-- placeholder -->
                        </div>

                        <div class="col-9 p-fluid">
                            <div class="custom-border"></div>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="transferChangedForNoTaxCalculation()" formControlName="transfergross" pInputText
                                    type="number" />
                                <label>+ Fracht</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="transferNetChanged()" formControlName="transfernet" pInputText
                                       type="number" />
                                <label>+ Fracht (Netto)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input (blur)="transfergrossChanged()" formControlName="transfergross" pInputText
                                       type="number" />
                                <label>+ Fracht (Brutto)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                            <!-- placeholder -->
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <!-- placeholder -->
                        </div>
                        <div class="col-9 p-fluid">
                            <div class="custom-border"></div>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input formControlName="endpricegross" pInputText type="number" readonly />
                                <label>= Endpreis</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input formControlName="endpricenet" pInputText type="number" readonly/>
                                <label>= Endpreis (Netto)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                            <span class="p-float-label p-input-icon-left">
                                <i class="pi pi-euro"></i>
                                <input formControlName="endpricegross" pInputText type="number" readonly />
                                <label>= Endpreis (Brutto)</label>
                            </span>
                        </div>
                        <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                            <!-- placeholder -->
                        </div>
                        <div class="col-4 mt-4 p-fluid">
                            <!-- placeholder -->
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
            <div>
                <div class="grid">
                    <div class="col-3" *ngIf="contractIsCreated">
                        <p-card [style]='styleDownloadFileCard' header="Vorvertragliche Informationen">
                            <div class="grid">
                                <div class="col-4">
                                </div>
                                <div class="col-4">
                                    <p-button icon="pi pi-download" label="Download"
                                    [disabled]="loadingPreContract" [loading]="loadingPreContract" (click)="onDownloadReport('preContract')"></p-button>
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                        </p-card>
                    </div>
                    <div class="col-3" *ngIf="contractIsCreated">
                        <p-card [style]='styleDownloadFileCard' header="Verbindliche Bestellung">
                            <div class="grid">
                                <div class="col-4">
                                </div>
                                <div class="col-4">
                                    <p-button icon="pi pi-download" label="Download"
                                    [disabled]="loadingContract" [loading]="loadingContract" (click)="onDownloadReport('contract')"></p-button>
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                        </p-card>
                    </div>
                    <div class="col-3" *ngIf="contractIsCreated">
                        <p-card [style]='styleDownloadFileCard' header="Anhang zur Bestellung">
                            <div class="grid">
                                <div class="col-4"></div>
                                <div class="col-4">
                                    <p-button icon="pi pi-download" label="Download" (click)="onDownloadReport('attachment')"
                                    [disabled]="loadingAttachment" [loading]="loadingAttachment"></p-button>
                                </div>
                                <div class="col-4"></div>
                            </div>
                        </p-card>
                    </div>

                    <div class="col-3" *ngIf="contractIsCreated">
                        <p-card [style]='styleDownloadFileCard' header="Auftragsbestaetigung">
                            <div class="grid">
                                <div class="col-4">
                                </div>
                                <div class="col-4">
                                    <p-button icon="pi pi-download" label="Download"
                                    [disabled]="loadingOrderConfirmation" [loading]="loadingOrderConfirmation" (click)="onDownloadReport('orderConfirmation')"></p-button>
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div>
            </div>
            <div *ngIf="!contractIsCreated" class="grid">
                <div class="col p-lg-2 pt-5" *ngIf="type !== 'edit'">
                    <button (click)="save()" icon="pi pi-save" label="Speichern"
                            pButton type="submit"></button>
                </div>
                <div class="col p-lg-2 pt-5" *ngIf="type == 'edit'">
                    <button (click)="update()" icon="pi pi-save" label="Aktualisieren"
                            pButton type="button"></button>
                </div>
            </div>
        </div>
        <!-- start preContractInfoPopup -->
      
        <p-dialog [(visible)]="isAgreementVisible" [closable]="false" [draggable]="false" [maximizable]="true"
            [modal]="true" [resizable]="false" appendTo="body" header="Eigene Vereinbarung hinzufügen">
            <div class="mt-4"></div>
            <p-editor [style]="{ height: '200px' }" [ngModel]="text" (ngModelChange)="onTextChange($event)"
                [ngModelOptions]="{standalone: true}"></p-editor>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button (click)="createAgreementToTable()" class="custom-save-button" icon="pi pi-paperclip"
                            label="Hinzufügen" pButton type="button"></button>
                        <button (click)="closeAgreementPopup()" class="custom-save-button p-button-danger"
                            icon="pi pi-times" label="Abbrechen" pButton type="button"></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
  
        <!--end start preContractInfoPopup -->
        <p-dialog [(visible)]="isAgreementSelectionVisible" [closable]="false" [draggable]="false" [maximizable]="true"
            [modal]="true" [resizable]="false" appendTo="body" header="Vereinbarungen">
            <p-card>
                <p-table #newcartable [globalFilterFields]="['id', 'content', 'description']" [loading]='loading'
                    [rows]="20" [scrollable]="true" selectionMode="multiple" [(selection)]="selectedAgreements"
                    [tableStyle]="{'min-width': '50rem'}" [value]="preContractInformations" dataKey="id" scrollHeight="flex"
                    styleClass=' p-datatable-striped'>

                    <ng-template pTemplate="header">
                        <tr>
                            <th width="40%">Inhalt</th>
                            <th width="40%">Beschreibung</th>
                            <th width="5%">Platzhalter</th>
                            <th width="5%">Standard</th>
                            <th width="10%">Hinzufügen</th>
                        </tr>
                    </ng-template>
                    <ng-template let-data pTemplate="body">
                        <tr>
                            <td [innerHTML]="data.content">
                                {{data.content}}
                            </td>
                            <td [innerHTML]="data.description">
                                {{data.description}}

                            </td>
                            <td>
                                <span *ngIf="checkState(data.placeholder)">
                                    <i class="pi pi-check-circle" style="color: green"></i>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="checkState(data.default)">
                                    <i class="pi pi-check-circle" style="color: green"></i>
                                </span>
                            </td>
                            <td>
                                <span >
                                    <p-tableCheckbox [value]="data" ></p-tableCheckbox>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-card>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button (click)="addAgreementTemplateToContract()" class="custom-save-button"
                                icon="pi pi-paperclip" label="Hinzufügen" pButton type="button"></button>
                        <button (click)="closeAgreementSelectionPopup()" class="custom-save-button p-button-danger"
                            icon="pi pi-times" label="Abbrechen" pButton type="button"></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
        <!-- TODO: change to new edit popup for agreements -->
        <p-dialog [(visible)]="showPciEditField" [closable]="false" [draggable]="false" [maximizable]="true"
            [modal]="true" [resizable]="false" appendTo="body" header="Vereinbarung bearbeiten">
            <p-editor [style]="{ height: '200px' }" formControlName="pciTableChange"></p-editor>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button (click)="editEntryInContractTable()" class="custom-save-button" icon="pi pi-paperclip"
                            label="Hinzufügen" pButton type="button"></button>
                        <button (click)="closePciEditPopup()" class="custom-save-button p-button-danger" icon="pi pi-times"
                            label="Abbrechen" pButton type="button"></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
    </form>
</app-sidebar>
