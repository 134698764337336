import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {PaginatorModule} from 'primeng/paginator';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RippleModule} from 'primeng/ripple';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent} from './dashboard.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ToastModule} from 'primeng/toast';
import {StepsModule} from 'primeng/steps';
import {ToolbarModule} from 'primeng/toolbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {PasswordModule} from 'primeng/password';
import {SkeletonModule} from 'primeng/skeleton';
import {SidebarModule} from 'primeng/sidebar';
import {MultiSelectModule} from "primeng/multiselect";
import {TabViewModule} from "primeng/tabview";
import {SharedModule} from "../shared/shared.module";
import {HomeComponent} from './home/home.component';
import {DataProtectionComponent} from './data-protection/data-protection.component';
import {AccordionModule} from "primeng/accordion";
import {CalendarModule} from "primeng/calendar";
import {ContractComponent} from './contract/contract.component';
import {NewCarComponent} from './new-car/new-car.component';
import {UsedCarComponent} from './used-car/used-car.component';
import {NewCamperComponent} from './new-camper/new-camper.component';
import {UsedCamperComponent} from './used-camper/used-camper.component';
import {OldtimerComponent} from './oldtimer/oldtimer.component';
import {SettingsComponent} from './settings/settings.component';
import {FileUploadModule} from "primeng/fileupload";
import {DayAdmissionComponent} from './day-admission/day-admission.component';
import {EditorModule} from "primeng/editor";
import { OrderconfirmationComponent } from './orderconfirmation/orderconfirmation.component';
import { NewOrderconfirmationComponent } from './new-orderconfirmation/new-orderconfirmation.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { NewUserComponent } from './user-management/new-user/new-user.component';
import { PriceConfirmationComponent } from './price-confirmation/price-confirmation.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';

@NgModule({
    declarations: [
        DashboardComponent, 
        HomeComponent, 
        DataProtectionComponent, 
        ContractComponent, 
        NewCarComponent, 
        UsedCarComponent, 
        NewCamperComponent, 
        UsedCamperComponent, 
        OldtimerComponent, 
        SettingsComponent, 
        DayAdmissionComponent, 
        OrderconfirmationComponent, 
        NewOrderconfirmationComponent, 
        UserManagementComponent,
         NewUserComponent,
         PriceConfirmationComponent],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        SharedModule,
        DropdownModule,
        FormsModule,
        ButtonModule,
        CardModule,
        MessagesModule,
        TableModule,
        DialogModule,
        ConfirmDialogModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        InputNumberModule,
        CheckboxModule,
        InputTextModule,
        SplitButtonModule,
        PaginatorModule,
        InputMaskModule,
        RippleModule,
        TooltipModule,
        SelectButtonModule,
        CardModule,
        ToastModule,
        StepsModule,
        ToolbarModule,
        RadioButtonModule,
        PasswordModule,
        SkeletonModule,
        SidebarModule,
        MultiSelectModule,
        TabViewModule,
        AccordionModule,
        CalendarModule,
        FileUploadModule,
        EditorModule,
        IconFieldModule,
        InputIconModule
    ],
    exports: [],
    providers: [ConfirmationService, DatePipe, HttpClient, MessageService],
})
export class DashboardModule {
}
