<app-sidebar>
    <p-card>
        <div class="grid">
            <div class="xl:col-9 lg:col-9 md:col-12 sm:col-12">
                <h2 class="mt-0">Verbindliche Bestellungen</h2>
            </div>
            <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12">
                <button
                        (click)="navigateToHome()"
                        pButton
                        type="button"
                        label="Vertrag anlegen"
                        icon="pi pi-plus"
                        class="table-header-button p-button-sm"
                ></button>
            </div>
        </div>
        <p-table
                #dt2
                [value]="contracts"
                [globalFilterFields]="['createdAt', 'buyerfirstname', 'vehicle', 'formtype', 'user.firstName']"
                class="mt-5"
                styleClass="p-datatable-striped"
                responsiveLayout="scroll"
                sortField="createdAt" [sortOrder]="-1">
            <ng-template pTemplate="caption">
                <div class="flex justify-content-between">
                    <p-button label="Clear" [outlined]="true" icon="pi pi-filter-slash" (onClick)="clear()" />
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" [(ngModel)]="searchValue" (input)="onFilterGlobal($event)" placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="createdAt">Erstellungsdatum
                        <p-sortIcon field="Erstellungsdatum"></p-sortIcon>
                    </th>
                    <th pSortableColumn="buyerfirstname">Kunde
                        <p-sortIcon field="Name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vehicle">Fahrzeug
                        <p-sortIcon field="Fahrzeug"></p-sortIcon>
                    </th>
                    <th pSortableColumn="formtype">Typ
                        <p-sortIcon field="Typ"></p-sortIcon>
                    </th>
                    <th pSortableColumn="user">User
                        <p-sortIcon field="user.firstName"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter 
                            type="text"
                            field="createdAt"
                            placeholder="Suche Erstellungsdatum" 
                            ariaLabel="Filter Name" />
                    </th>
                    <th>
                        <p-columnFilter 
                            type="text"
                            field="buyerfirstname"
                            placeholder="Suche Kunde" 
                            ariaLabel="Filter Country" />
                    </th>
                    <th>
                        <p-columnFilter 
                            type="text"
                            field="vehicle"
                            placeholder="Suche Fahrzeug"
                            ariaLabel="Filter Fahrzeug" />
                    </th>
                    <th>
                        <p-columnFilter 
                            type="text"
                            field="formtype"
                            placeholder="Suche Typ"
                            ariaLabel="Filter Typ" />
                    </th>
                    <th>
                        <p-columnFilter 
                            type="text"
                            field="user.firstName"
                            placeholder="Suche User"
                            ariaLabel="Filter user" />
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-submissionData>
                <tr>
                    <td>{{ getFormattedCreatedAt(submissionData.createdAt) }}</td>
                    <td>{{ submissionData.buyerfirstname + ' ' + submissionData.buyerlastname }}</td>
                    <td>{{ submissionData.vehicle }}</td>
                    <td>{{ getEnumName(submissionData.formtype) }}</td>
                    <td>{{ getUsername(submissionData.user) }}</td>
                    <td>
                        <button
                                pButton
                                icon="pi pi-search"
                                (click)="showSubmissionDetails(submissionData.id, submissionData.formtype, 'view')"
                                class="custom-table-button"
                        ></button>
                        <button
                                pButton
                                icon="p-button-icon pi pi-pencil"
                                (click)="showSubmissionDetails(submissionData.id, submissionData.formtype, 'edit')"
                                class="custom-table-button"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</app-sidebar>
